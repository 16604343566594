<template>
	<Layout>
		<Header>
			<b-button v-if="permissionEnabled('clients', 'create')" type="is-secondary create" rounded @click="modalCreate($event)">
				<svg-icon icon="plus" class="icon is-small"></svg-icon>
				<span>{{ $t('buttons.add') }}</span>
			</b-button>
		</Header>
		<filter-default enable-search @filterSearch="filterSearch" />
		<Error v-if="errored" :icon="true" :back="true" />
		<Results v-if="clients.length == 0 && !loading" />
		<div v-if="loading" class="columns is-multiline">
			<div v-for="n in pagination" :key="n" class="column is-12-mobile is-6-tablet is-4-desktop">
				<Placeholder />
			</div>
		</div>
		<transition-group name="filtering" class="filtering columns is-multiline mb-5" tag="div">
			<div v-for="c in clients" :key="c.id" class="column is-12-mobile is-6-tablet is-4-desktop">
				<article class="block">
					<div class="block__logo">
						<img :src="c.logo_url" :alt="c.name" />
					</div>
					<div class="block__content">
						<h3 class="block__name link"  @click="modalEdit(c.id)">{{ c.name }}</h3>
						<p class="block__email">24 vidas • {{ format(c.created_at) }}</p>
					</div>
					<Trigger :id="c.id" page="clients" />
				</article>
			</div>
		</transition-group>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Icon from '@/components/Icon'
import Placeholder from '@/components/placeholders/Company'
import Trigger from '@/components/triggers/Global'
import Error from '@/components/Error'
import Results from '@/components/Results'
import Modal from '@/components/modals/Client'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { create, update } from '@/mixins/modal'
import { successToast, errorToast } from '@/mixins/toast'
import { mapGetters } from 'vuex'
import mixinApp from '@/mixins/app'
import FilterDefault from '@/components/FilterDefault'

export default {
	name: 'ClientsPage',
	components: {
		Layout,
		Header,
		Placeholder,
		Trigger,
		Error,
		Results,
		'svg-icon': Icon,
		FilterDefault
	},
	mixins: [
		mixinApp
	],
	data() {
		return {
			current: 1,
			page: 1,
			total: 0,
			pagination: 15,
			data: [],
			status: 1,
			loading: true,
			errored: false,
			permission: [],
			showPagination: false,
			filter: {
				name: ''
			}
		}
	},
	mounted() {
		this.getAllClients()
		eventHub.$on('edit-modal-clients', obj => {
			update(this, 'clients', obj.id, Modal, 'Edit', obj.root)
		})
		eventHub.$on('delete-clients', obj => {
			this.$buefy.dialog.alert({
				size: 'is-delete',
				type: 'is-outlined is-primary',
				title: this.$t('labels.warning'),
				message: '<span>' + this.$t('alerts.delete.confirmation') + '</span>',
				canCancel: true,
				focusOn: 'cancel',
				cancelText: this.$t('buttons.no'),
				confirmText: this.$t('buttons.yes'),
				onConfirm: async () => {
					try {
						const response = await Api.delete(`clients/destroy/${obj.id}`)
						if (response.status === 200) {
							successToast(this.$t('alerts.delete.success', [this.$tc('menu.clients')]))
							await this.getAllClients()
						} else {
							errorToast(this.$t('alerts.delete.error', [this.$tc('menu.clients')]))
						}
					} catch (e) {
						console.log(e)
					}
				}
			})
		})
		eventHub.$on('reload-clients', () => {
			this.getAllClients()
		})
	},
	computed: {
		clients() {
			const url = window.location.href.split('/')
			const type = url.find(el => el === 'page')
			if (type === 'page') {
				let current = this.current - 1
				return this.data.slice(current * this.pagination, (current + 1) * this.pagination)
			} else {
				let current = this.current - 1
				return this.data.slice(current * this.pagination, (current + 1) * this.pagination)
			}
		},
		...mapGetters('user', [
			'permissionEnabled'
		])
	},
	methods: {
		async getAllClients() {
			try {
				const response = await Api.get('clients/findAll')
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.data = data
					this.total = data.length
					this.showPagination = this.total > 15 ? true : false
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async filterSearch(v) {
			try {
				this.filter.name = v
				if (v.length > 0) {
					const response = await Api.post('clients/findByName', {
						name: this.filter.name
					})
					const { status } = response
					if (status === 200) {
						const { data } = response
						this.data = data
						this.total = data.length
					}
				} else {
					await this.getAllClients()
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		modalCreate() {
			if (this.permissionEnabled('clients', 'create')) {
				create(this, 'clients', Modal, 'New')
			}
		},
		modalEdit(id) {
			if (this.permissionEnabled('clients', 'edit')) {
				update(this, 'clients', id, Modal, 'Edit')
			}
		}
	},
  destroyed() {
		eventHub.$off('edit-modal-clients')
		eventHub.$off('reload-clients')
		eventHub.$off('delete-clients')
	}
}
</script>
