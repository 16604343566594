<template>
	<b-dropdown class="block__dropdown" trigger="click" position="is-bottom-left">
		<svg-icon class="dots" slot="trigger" icon="dots"></svg-icon>
		<b-dropdown-item v-for="(l, i) in items" :key="i" :id="`action-${l.type}`" :class="l.color" @click="handleClick(l, id)">
			<svg-icon :icon="l.icon"></svg-icon>
			<span>{{ $t(l.name) }}</span>
		</b-dropdown-item>
	</b-dropdown>
</template>
<script>
import Icon from '@/components/Icon'
import eventHub from '@/services/eventHub'
import { mapGetters } from 'vuex'
import { ModalProgrammatic as CustomModal } from 'buefy'
import Cases from '@/components/modals/Cases'

export default {
	name: 'Trigger',
	components: {
		'svg-icon': Icon
	},
	props: {
		id: {
			type: [Number, String],
			required: true
		},
		page: {
			type: String,
			required: true
		},
		lawyer: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			items: []
		}
	},
	mounted() {
		let itemsTemp = []

		if (this.lawyer.lawyer.lawsuits.length > 0) {
			itemsTemp.push({
				id: 1,
				name: 'labels.cases',
				icon: 'lawsuits',
				color: 'has-text-info',
				type: 'cases'
			})
		}

		itemsTemp.push({
			id: 2,
			name: 'labels.edit',
			icon: 'edit',
			color: 'has-text-info',
			type: 'edit'
		})

		itemsTemp.push({
			id: 3,
			name: 'labels.delete',
			icon: 'trash',
			color: 'has-text-danger',
			type: 'delete'
		})

		this.items = itemsTemp
	},
	methods: {
		handleClick(el, id) {
			const type = el.type
			if (type === 'edit') {
				eventHub.$emit(`edit-modal-${this.page}`, {
					id
				})
			}

			if (type === 'delete') {
				eventHub.$emit(`delete-${this.page}`, {
					id
				})
			}

			if (type === 'cases') {
				CustomModal.open({
					parent: this,
					component: Cases,
					scroll: 'keep',
					customClass: 'is-cases',
					trapFocus: true,
					props: {
						lawyer: this.lawyer
					}
				})
			}
		}
	},
	computed: {
		...mapGetters('user', ['permissionEnabled'])
	}
}
</script>
