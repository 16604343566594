<template>
	<transition name="slide-fade" mode="out-in">
		<Layout>
			<b-loading :is-full-page="false" v-model="loadingRedirect"></b-loading>
			<ValidationObserver class="auth__form" ref="observer" v-slot="{ handleSubmit }">
				<form @submit.prevent="handleSubmit(signIn)">
					<Logo />
					<div class="mb-5 text-center">
						<h1 class="has-text-primary is-size-3 is-bold">{{ $t('auth.login') }}</h1>
					</div>

					<InputWithValidation tab="1" class="mb-5" rules="required|email" field="email" name="email" :label="$t('fields.email')" size="is-medium" v-model="auth.email" />

					<InputWithValidation tab="2" class="mb-5" rules="required|min:6" field="password" name="password" :label="$t('fields.password')" vid="password" size="is-medium" password-reveal v-model="auth.password">
						<router-link id="link-forgot" :to="{ name: 'Forgot' }" class="auth__forgot is-primary is-semibold" tabindex="4">{{ $t('auth.password_forgot') }}</router-link>
					</InputWithValidation>

					<span class="is-block text-center" v-if="reCaptcha">
						<vue-recaptcha sitekey="6LdwjmUbAAAAAPoh61kQi64a21H06Y2ocn2YdiRC" :loadRecaptchaScript="true" ref="recaptcha" @verify="onVerify" />
					</span>

					<span class="is-block text-center">
						<b-button id="button-login" tabindex="3" native-type="submit" class="button is-button is-secondary" :loading="loading">{{ $t('auth.login') }}</b-button>
					</span>

					<div v-if="enableSocialLogin && !loadingRedirect">
						<span class="auth__or">
							<span>{{ $t('auth.social_login') }}</span>
						</span>

						<span class="auth__buttons">
							<b-button v-for="(s, i) in social" :key="i" :id="`button-${s}`" native-type="button" class="button is-button auth__social" :class="s" @click="redirectSocial(s)">
								<svg-icon class="icon" :icon="s"></svg-icon>
							</b-button>
						</span>
					</div>
				</form>
			</ValidationObserver>
		</Layout>
	</transition>
</template>

<script>
import Layout from '@/layouts/Auth'
import Logo from '@/components/Logo'
import Icon from '@/components/Icon'
import InputWithValidation from '@/components/inputs/InputWithValidation'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import { ToastProgrammatic as Toast } from 'buefy'
import VueRecaptcha from 'vue-recaptcha'
import { mapActions, mapState } from 'vuex'

export default {
	name: 'Login',
	components: {
		Layout,
		Logo,
		InputWithValidation,
		ValidationObserver,
		VueRecaptcha,
		'svg-icon': Icon
	},
	data() {
		return {
			loading: false,
			auth: {
				email: '',
				password: ''
			},
			counter: 0,
			reCaptcha: false,
			reCaptchaSuccess: false,
			social: ['facebook', 'linkedin', 'twitter', 'gmail'],
			enableSocialLogin: process.env.VUE_APP_SOCIAL_LOGIN === 'true' ? true : false
		}
	},
	created() {
		localStorage.removeItem('@stup:user')
		localStorage.removeItem('@stup:permission')
	},
	methods: {
		...mapActions('user', [
			'getUserData'
		]),
		redirectSocial(driver) {
			if (driver == 'gmail') {
				driver = 'google'
			}

			window.location.href = `${process.env.VUE_APP_BASE_API}auth/${driver}/redirect`
		},
		onVerify(response) {
			if (response) {
				this.reCaptchaSuccess = false
			}
		},
		async signIn() {
			try {
				this.loading = true
				if (this.reCaptchaSuccess && this.reCaptcha) {
					Toast.open({
						message: this.$t('login_error'),
						type: 'is-danger',
						position: 'is-bottom'
					})
					this.$refs.recaptcha.reset()
					return
				}
				const response = await Api.post('user/authenticate', this.auth)
				const { status } = response
				if (status === 200) {
					const { token } = response.data
					localStorage.setItem('@stup:token', token)
					this.getUserData()
					await this.$router.push('dashboard')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					Toast.open({
						message,
						type: 'is-danger',
						position: 'is-bottom'
					})
					if (this.reCaptcha) {
						this.$refs.recaptcha.reset()
					} else {
						this.counter++
						if (this.counter > 0) {
							this.reCaptcha = true
							this.reCaptchaSuccess = true
						}
					}
				}
			} finally {
				this.loading = false
			}
		}
	},
	computed: {
		...mapState('user', [
			'loadingRedirect'
		])
	}
}
</script>
