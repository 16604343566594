<template>
	<div class="columns is-multiline is-mobile mb-5">
		<div class="column is-12-mobile is-12-tablet is-12-desktop is-5-widescreen">
			<article class="block no-hover full">
				<div class="column is-flex is-8 pr-0">
					<div class="block__logo">
						<b-skeleton width="130px" height="40px"></b-skeleton>
					</div>
					<div class="block__content pt-1">
						<b-skeleton width="60%" height="15"></b-skeleton>
						<b-skeleton width="80%" height="10"></b-skeleton>
					</div>
				</div>
				<div class="column is-4 pr-0">
					<div class="block__content is-align-items-center p-0">
						<b-skeleton width="30%" height="15"></b-skeleton>
						<b-skeleton width="60%" height="10"></b-skeleton>
					</div>
				</div>
			</article>
		</div>
		<div class="column is-12-mobile is-12-tablet is-12-desktop is-7-widescreen">
			<div class="columns is-multiline is-mobile">
				<div v-for="index in 5" :key="index" class="column">
					<div class="stats">
						<b-skeleton width="45" height="30"></b-skeleton>
						<b-skeleton width="60%" height="10"></b-skeleton>
					</div>
				</div>
			</div>
		</div>
		<div class="column is-12 lawsuit-tabs placeholder">
			<b-tabs type="is-boxed">
				<b-tab-item>
					<div class="columns">
						<div class="column is-12-mobile is-12-desktop is-9-widescreen pt-5">
							<b-skeleton width="300" height="35"></b-skeleton>
							<ul class="is-flex data-list mt-5">
								<li v-for="index in 4" :key="index">
									<b-skeleton width="35%" height="10"></b-skeleton>
									<b-skeleton width="100%" height="30"></b-skeleton>
								</li>
							</ul>
							<hr class="mt-0">
							<b-skeleton width="300" height="35"></b-skeleton>
							<ul class="is-flex data-list">
								<li v-for="index in 3" :key="index">
									<b-skeleton width="35%" height="10"></b-skeleton>
									<b-skeleton width="100%" height="30"></b-skeleton>
								</li>
							</ul>
							<ul class="is-flex data-list mb-0">
								<li class="full">
									<b-skeleton width="15%" height="10"></b-skeleton>
									<b-skeleton width="50%" height="30"></b-skeleton>
								</li>
								<li class="full">
									<b-skeleton width="15%" height="10"></b-skeleton>
									<b-skeleton width="20%" height="30"></b-skeleton>
								</li>
							</ul>
						</div>
						<div class="column is-12-mobile is-12-desktop is-3-widescreen">
							<div class="data-action placeholder" v-for="index in 3" :key="index">
								<b-skeleton width="30%" height="15"></b-skeleton>
								<b-skeleton width="80%" height="38"></b-skeleton>
							</div>
						</div>
					</div>
				</b-tab-item>
			</b-tabs>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Placeholder'
}
</script>
