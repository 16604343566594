<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form autocomplete="off" ref="form" @submit.prevent="handleSubmit(saveUser)">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>{{ $t(modalTitle(name)) }} <strong>{{ $tc('menu.users') }}</strong></span>
					<span v-if="user.id">#{{ user.id }}</span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="modal-card mb-3">
					<div class="columns">
						<div class="column">
							<InputWithValidation name="name" rules="required" :label="$t('fields.first_name')" v-model="user.first_name" />
						</div>
						<div class="column">
							<InputWithValidation rules="required" :label="$t('fields.last_name')" v-model="user.last_name" />
						</div>
					</div>

					<InputWithValidation class="mb-4" rules="required|email" field="email" :label="$t('fields.email')" v-model="user.email" :disabled="user.id === 1 || user.id === 2" />
					<SelectWithValidation class="mb-4" rules="required" :label="$tc('menu.roles')" v-model="user.permission_id" :disabled="user.id === 1 || user.id === 2">
						<option v-for="r in permission" :value="r.id" :key="r.id" v-show="r.id != 3">{{ r.name }}</option>
					</SelectWithValidation>

					<SelectWithValidation v-if="user.permission_id && [2, 4].includes(user.permission_id)" class="mb-4" :label="$tc('menu.insurance')" rules="required" v-model="user.client_id">
						<option v-for="c in clients" :value="c.id" :key="`clients-${c.id}`">{{ c.name }}</option>
					</SelectWithValidation>

					<InputWithValidation rules="required|min:8" field="password" :label="$t('fields.password')" password-reveal v-model="user.password" v-if="name === 'New'" />
					<InputWithValidation type="password" :label="$t('fields.password')" password-reveal v-model="user.password" v-if="name === 'Edit'" :disabled="root === 'root'" />
					<password-meter class="mb-2" :password="user.password" />

					<small class="modal-updated" v-if="user.updated_at">{{ $t('labels.last_change') }} {{ format(user.updated_at) }}</small>
				</div>
			</div>
			<Footer :loading="loading" />
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import SelectWithValidation from '@/components/inputs/SelectWithValidation'
import { ValidationObserver } from 'vee-validate'
import PasswordMeter from 'vue-simple-password-meter'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import Footer from '@/components/modals/Footer'
import '@/mixins/generic'

export default {
	components: {
		Footer,
		InputWithValidation,
		SelectWithValidation,
		ValidationObserver,
		PasswordMeter
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			user: {
				email: ''
			},
			role: 1,
			permission: [],
			clients: [],
			visible: false
		}
	},
	methods: {
		async getAllRoles() {
			try {
				const response = await Api.get('permission/findAll')
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.permission = data
				}
			} catch (e) {
				console.log(e)
			}
		},
		async getClients() {
			try {
				const { status, data } = await Api.get(`app/clientSelect`)
				if (status === 200) {
				this.clients = data
				}
			} catch (e) {
				console.log(e)
			}
		},
		async update() {
			try {
				this.loading = true
				const response = await Api.put(`users/update/${this.id}`, this.user)
				const { status } = response
				if (status === 200) {
					// const { message } = response.data
					this.$emit('close')
					history.pushState({}, '', '/users')
					successToast(this.$t('alerts.update.success', [this.$tc('menu.users')]))
					eventHub.$emit('reload-users')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					// const { message } = e.data
					errorToast(this.$t('alerts.update.error', [this.$tc('menu.users')]))
				}
			} finally {
				this.loading = false
			}
		},
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`users/findById/${this.id}`)
					const { status } = response
					if (status === 200) {
						const { data } = response
						this.user = data
						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}
			}
		},
		async store() {
			try {
				this.loading = true
				const response = await Api.post('users/store', this.user)
				const { status } = response
				if (status === 201 || status === 200) {
					// const { message } = response.data
					this.$emit('close')
					history.pushState({}, '', '/users')
					successToast(this.$t('alerts.create.success', [this.$tc('menu.users')]))
					eventHub.$emit('reload-users')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					errorToast(message)
				}
			} finally {
				this.loading = false
			}

			this.loading = true
			setTimeout(() => {
				this.loading = false
			}, 1000)
		},
		async saveUser() {
			this.name === 'New' ? await this.store() : await this.update()
		}
	},
	mounted() {
		this.findById()
		this.getAllRoles()
		this.getClients()
		this.user.password = this.passwordGenerator()
	}
}
</script>
