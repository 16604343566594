<template>
  <LawyerInputs :id="id" :name="name" />
</template>

<script>
import LawyerInputs from '@/components/modals/LawyerInputs'

export default {
	components: {
    LawyerInputs
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	}
}
</script>
