<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form autocomplete="off" ref="form" @submit.prevent="handleSubmit(saveClient)">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>
						{{ $t(modalTitle(name)) }}
						<strong>{{ $tc('menu.customers') }}</strong>
					</span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="modal-card mb-3">
					<div class="columns is-multiline">
						<div class="column is-12">
							<InputWithValidation name="name" rules="required" :label="$t('fields.first_name')" v-model="client.name" />
						</div>
						<div class="column is-8">
							<InputWithValidation name="responsible" rules="required" :label="$t('fields.responsible')" v-model="client.responsible" />
						</div>
						<div class="column is-4">
							<InputWithValidation name="rule" v-mask="'##'" rules="required|min:2" :label="$t('fields.death')" placeholder="30" v-model="client.rule" />
							<small>Ex: 30, 60 ou 90</small>
						</div>
						<div class="column">
							<h3 class="is-size-5 is-semibold has-text-primary">{{ $t('labels.customization') }}</h3>
							<hr class="my-3" />
						</div>
						<div class="column is-12 is-flex is-align-items-end w-full is-relative mb-2">
							<InputWithValidation name="subdomain" rules="required|max:25" :label="$t('fields.subdomain')" v-mask="'NNNNNNNNNNNNNNNNNNNNNNNNN'" v-model="client.subdomain" />
							<span class="domain" :class="{ 'filled' : client.subdomain }"><strong>{{ client.subdomain ? client.subdomain : 'domain' }}</strong>.assistenciainventario.com.br</span>
						</div>
						<div class="column is-8">
							<label class="label">
								<span>{{ $tc('fields.logo') }} </span>
								<span class="required">*</span>
							</label>
							<b-upload v-model="client.logo" class="logo" accept="image/png" drag-drop>
								{{ client.logo ? client.logo.name : 'Imagem' }}
              </b-upload>
						</div>
						<div class="column is-4 theme-color">
							<label class="label no-wrap">
								<span>{{ $tc('fields.color', 2) }} </span>
								<span class="required">*</span>
							</label>
							<div class="is-flex">
								<v-swatches v-model="client.color_primary" :swatches="swatches" row-length="5" popover-x="left" show-fallback fallback-input-type="color"></v-swatches>
								<v-swatches v-model="client.color_secondary" :swatches="swatches" row-length="5" popover-x="left" show-fallback fallback-input-type="color"></v-swatches>
							</div>
						</div>
					</div>
					<small class="modal-updated" v-if="client.updated_at">{{ $t('labels.last_change') }} {{ format(client.updated_at) }}</small>
				</div>
			</div>
			<Footer :loading="loading" />
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import { ValidationObserver } from 'vee-validate'
import VSwatches from 'vue-swatches'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorLaravel } from '@/mixins/toast'
import Footer from '@/components/modals/Footer'
import '@/mixins/generic'

export default {
	components: {
		Footer,
		InputWithValidation,
		ValidationObserver,
		VSwatches
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
      client: {
        color_primary: '',
        color_secondary: ''
      },
			isOpening: false,
			loading: false,
			visible: false,
			swatches: this.defaultColors(),
      configRequest: {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		}
	},
	methods: {
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`clients/findById/${this.id}`)
					if (response.status === 200) {
						this.client = { ...response.data, logo: null }
						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}
			}
		},
    getFormData() {
      let formData = new FormData()

      formData.append('name', this.client.name)
      formData.append('responsible', this.client.responsible)
      formData.append('rule', this.client.rule)
      formData.append('subdomain', this.client.subdomain)

      if (this.client.logo) {
        formData.append('logo', this.client.logo)
      }

      formData.append('color_primary', this.client.color_primary)
      formData.append('color_secondary', this.client.color_secondary)

      return formData
    },
		async store() {
			try {
				this.loading = true
				const response = await Api.post('clients/store', this.getFormData(), this.configRequest)
				if (response.status === 201 || response.status === 200) {
					this.$emit('close')
					history.pushState({}, '', '/clients')
					successToast(this.$t('alerts.create.success', [this.$tc('menu.clients')]))
					eventHub.$emit('reload-clients')
				}
			} catch (e) {
        console.log(e)
        errorLaravel(e, 'Erro ao salvar o cliente')
			} finally {
				this.loading = false
			}
		},
    async update() {
			try {
				this.loading = true
        let formData = this.getFormData()
        formData.append('_method', 'put')
				const response = await Api.post(`clients/update/${this.id}`, formData, this.configRequest)
				if (response.status === 200) {
					this.$emit('close')
					history.pushState({}, '', '/clients')
					successToast(this.$t('alerts.update.success', [this.$tc('menu.clients')]))
					eventHub.$emit('reload-clients')
				}
			} catch (e) {
				console.log(e)
        errorLaravel(e, 'Erro ao atualizar o cliente')
			} finally {
				this.loading = false
			}
		},
		async saveClient() {
			this.name === 'New' ? await this.store() : await this.update()
		}
	},
	mounted() {
		this.findById()
	}
}
</script>
