<template>
	<Layout>
		<Header :bordered="false" />
		<div class="dashboard">
			<b-loading :is-full-page="false" v-model="loading"></b-loading>
			<b-carousel-list v-if="overview.length > 0" animated="slide" :data="overview" :has-drag="true" :items-to-show="isDesktop ? 5 : isTablet ? 4 : 2">
				<template #item="overview">
					<div class="dashboard__box px-2 pt-3 pb-5">
						<h4 class="dashboard__box__value">
							{{ overview.value }}
						</h4>
						<span v-if="overview.type.charAt(0) == '$'" class="dashboard__box__type">{{ $tc('menu.roles', [overview.type.length]) }}</span>
						<span v-else class="dashboard__box__type">{{ overview.type }}</span>
					</div>
				</template>
			</b-carousel-list>
			<span v-else class="dashboard__dashboard">
				<b-skeleton v-for="i in 5" :key="i" width="250" height="120" class="dashboard__box"></b-skeleton>
			</span>
			<section class="columns is-multiline is-mobile mt-5">
				<Users />
				<article class="column is-12-mobile is-two-third-desktop">
					<BigNumbers />
				</article>
			</section>
		</div>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Users from '@/components/Users.vue'
import BigNumbers from '@/components/BigNumbers.vue'
import Api from '@/services/api'
import { responsive } from '@/mixins/responsive'
import '@/mixins/generic'

export default {
	name: 'Dashboard',
	mixins: [responsive],
	components: {
		Layout,
		Header,
		Users,
		BigNumbers
	},
	data() {
		return {
			loading: false,
			bordered: true,
			overview: []
		}
	},
	mounted() {
		this.getOverview()
	},
	methods: {
		fillChartData() {
			let labelsChart = [], dataChart = []

			this.overview.forEach((o, i) => {
				if (i > 0) {
					labelsChart.push(`${o.type} (${o.value})`)
					dataChart.push(o.value)
				}
			})
		},
		getOverview() {
			Api.get('dashboard/overview')
				.then(({ data }) => {
					this.overview = data
					this.fillChartData()
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}
}
</script>
