<template>
	<Layout>
		<Header>
			<!-- <b-button v-if="permissionEnabled('users', 'create')" type="is-secondary create" rounded @click="modalCreate($event)">
				<svg-icon icon="plus" class="icon is-small"></svg-icon>
				<span>{{ $t('buttons.add') }}</span>
			</b-button> -->
		</Header>
		<filter-default
			:filters="filters"
			@filterCompany="filterCompany"
			@filterSearch="filterSearch"
			enable-search
		/>
		<Error v-if="errored" :icon="true" :back="true" />
		<Results v-if="lawsuits.length == 0 && !loading" />
		<div v-if="loading" class="columns is-multiline">
			<div v-for="n in pagination" :key="n" class="column is-12">
				<Placeholder />
			</div>
		</div>
		<transition-group name="filtering" class="filtering columns is-multiline is-mobile mb-5" tag="div">
			<div v-for="l in lawsuits" :key="l.id" class="column is-12">
				<article class="block full">
					<div class="column is-flex is-9-mobile is-9-tablet is-4-desktop">
						<div class="block__logo">
							<img :src="l.client.logo_url" :alt="l.people.name" />
						</div>
						<div class="block__content pt-1">
							<h3 class="block__name">
								<a  :href="`lawsuit/${l.id}`">{{ l.people.name }}</a>
								<span class="status-process judicial" title="Processo Judicial"></span>
							</h3>
							<p class="block__email">{{ format(l.created_at) }} • {{ timeTo(l.created_at) }}</p>
						</div>
					</div>
					<div class="block__content column is-3-mobile is-3-tablet is-2-desktop">
						<h4 class="block__label">Advogado</h4>
						<span v-if="l.lawyer" class="block__value" @click="modalLawyers(l.id)">{{ l.lawyer.user.full_name }}</span>
						<span v-else class="block__value link" @click="modalLawyers(l.id)">Definir</span>
					</div>
					<div class="block__content column is-2-desktop">
						<h4 class="block__label">Última movimentação</h4>
						<p class="block__value">{{ timeFrom(l.date_last_change) }}</p>
					</div>
					<div class="block__content column is-2-desktop">
						<h4 class="block__label">({{ l.comments_count }}) <span>Comentários</span></h4>
						<h4 class="block__label">({{ l.files_count }}) <span>Arquivos</span></h4>
					</div>
					<div class="block__content column is-2-desktop is-align-items-center">
						<h4 class="block__label">Status</h4>
						<!-- <p class="block__value has-text-success">Aguardando Início</p> -->
						<p :class="`block__value ${processStatusColor(l.status)}`">{{ processStatusText(l.status) }}</p>
					</div>
				</article>
			</div>
		</transition-group>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
// import Icon from '@/components/Icon'
import Placeholder from '@/components/placeholders/Lawsuits'
import Error from '@/components/Error'
import Results from '@/components/Results'
import Modal from '@/components/modals/Lawyer'
import Lawyers from '@/components/modals/Lawyers'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { create, update } from '@/mixins/modal'
import { successToast, errorToast } from '@/mixins/toast'
import { mapState, mapGetters } from 'vuex'
import mixinApp from '@/mixins/app'
import FilterDefault from '@/components/FilterDefault'

export default {
	name: 'lawsuitsPage',
	components: {
		Layout,
		Header,
		Placeholder,
		Error,
		Results,
		// 'svg-icon': Icon,
		FilterDefault
	},
	mixins: [
		mixinApp
	],
	data() {
		return {
			current: 1,
			page: 1,
			total: 0,
			pagination: 15,
			data: [],
			status: 1,
			loading: true,
			errored: false,
			permission: [],
			showPagination: false,
			filter: {
				name: '',
				client: null,
			},
			filters: []
		}
	},
	mounted() {
		this.getAllLawsuits()
        this.getClients()
		eventHub.$on('edit-modal-lawsuits', obj => {
			update(this, 'lawsuits', obj.id, Modal, 'Edit', obj.root)
		})
		eventHub.$on('set-lawyer-lawsuit', obj => {
			Api.post(`lawsuits/setLawyer`, { lawsuit_id: obj.lawsuitId, lawyer_id: obj.laweyrId })
				.then(() => {
					successToast('Selecionado com sucesso')
					this.getAllLawsuits()
				})
				.catch(() => {
					errorToast('Erro ao selecionar o advogado')
				})
		})
		eventHub.$on('delete-lawsuits', obj => {
			this.$buefy.dialog.alert({
				size: 'is-delete',
				type: 'is-outlined is-primary',
				title: this.$t('labels.warning'),
				message: '<span>' + this.$t('alerts.delete.confirmation') + '</span>',
				canCancel: true,
				focusOn: 'cancel',
				cancelText: this.$t('buttons.no'),
				confirmText: this.$t('buttons.yes'),
				onConfirm: async () => {
					try {
						const response = await Api.delete(`lawsuits/destroy/${obj.id}`)
						const { status } = response
						if (status === 200) {
							successToast(this.$t('alerts.delete.success', [this.$tc('menu.laywer')]))
							await this.getAllLawsuits()
						} else {
							errorToast(this.$t('alerts.delete.error', [this.$tc('menu.laywer')]))
						}
					} catch (e) {
						console.log(e)
					}
				}
			})
		})
		eventHub.$on('reload-lawsuits', () => {
			this.getAllLawsuits()
		})
	},
	computed: {
		lawsuits() {
			const url = window.location.href.split('/')
			const type = url.find(el => el === 'page')
			if (type === 'page') {
				let current = this.current - 1
				return this.data.slice(current * this.pagination, (current + 1) * this.pagination)
			} else {
				let current = this.current - 1
				return this.data.slice(current * this.pagination, (current + 1) * this.pagination)
			}
		},
		...mapGetters('user', [
			'permissionEnabled'
		]),
    ...mapState('user', [
      'user'
    ])
	},
	destroyed() {
		eventHub.$off('edit-modal-lawsuits')
		eventHub.$off('set-lawyer-lawsuit')
		eventHub.$off('delete-lawsuits')
		eventHub.$off('reload-lawsuits')
	},
	methods: {
        async getClients() {
            if (this.user && this.user.permission_id == this.permissionMaster) {
                try {
                    const { status, data } = await Api.get(`app/clientSelect`)
                    if (status === 200) {
                        let options = data.map(d => {
                            return {
                                label: d.name,
                                value: `${d.id}`
                            }
                        })

                        this.filters.push({
                            title: 'Cliente',
                            function: 'filterCompany',
                            options: options
                        })
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        },
		async getAllLawsuits() {
			try {
				const response = await Api.get('lawsuits/findAll')
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.data = data
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async filterSearch(v) {
			try {
				this.loading = true
				this.data = []
				this.filter.name = v
				if (v.length > 0) {
					const response = await Api.post('lawsuits/findByName', {
						name: this.filter.name,
						client: this.filter.client
					})

					if (response.status === 200) {
						this.data = response.data
					}
				} else {
					await this.getAllLawsuits()
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async filterCompany (v) {
            try {
				this.loading = true
				this.data = []
				this.filter.client = v
				const response = await Api.post('lawsuits/filterByClient', {
					name: this.filter.name,
					client: v
				})

				if (response.status === 200) {
					this.data = response.data
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		modalCreate() {
			if (this.permissionEnabled('lawsuits', 'create')) {
				create(this, 'lawsuits', Modal, 'New')
			}
		},
		modalLawyers(id) {
			if (this.user && this.user.permission_id != this.permissionUser) {
				this.$buefy.modal.open({
					parent: this,
					component: Lawyers,
					scroll: 'clip',
					customClass: 'is-lawyer',
					trapFocus: true,
					props: {
						lawsuitId: id
					}
				})
			}
		}
	}
}
</script>
