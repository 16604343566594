<template>
	<div class="lawyers-page">
		<div class="logo">
			<img src="@/assets/images/logo-light.png" />
		</div>
		<div class="head">
			<h2 class="is-size-2 is-bold has-text-primary">Credenciamento</h2>
			<p>Grande parte das brasileiras <strong>nunca teve a experiência de ser atendida por uma advogada</strong>. Muitas não sabem o quão determinante isso pode ser para a aplicação da Justiça no caso prático.</p>
			<p>Em casos de violência doméstica, este atendimento exige muito mais que conhecimento jurídico teórico. <strong>Empatia e acolhimento fazem toda a diferença</strong> para que a vítima sinta-se amparada e confiante para agir. <strong>Experiência prática e muita coragem</strong> são fundamentais para quem atua na linha de frente.</p>
			<p>Conscientizar as mulheres sobre o que uma advogada especializada pode fazer por elas é um dos nossos compromissos. Só quem entende isso pode valorizar o seu trabalho e buscar a melhor ajuda profissional, especialmente nesse momento de grande vulnerabilidade.</p>
			<p>Além disso, <strong>ajudamos as usuárias a encontrar a advogada ideal utilizando exclusivamente as preferências da usuária</strong>, da forma mais rápida, discreta e segura possível.</p>
		</div>
		<div class="form">
			<LawyerInputs name="New" :publicPage="true" />
		</div>
	</div>
</template>

<script>
import LawyerInputs from '@/components/modals/LawyerInputs'

export default {
	name: 'LawyerRegister',
	components: {
		LawyerInputs
	},
	data() {
		return {
			laywer: {}
		}
	}
}
</script>
