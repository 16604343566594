<template>
	<div>
		<header class="modal-card-head">
			<h4 class="modal-card-title">
				<span>Casos de <strong>{{ lawyer.full_name }}</strong></span>
			</h4>
		</header>
		<div class="modal-card-body">
			<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
			<div class="modal-card mb-3">
				<ul class="lawyer-cases">
					<li v-for="l in lawyer.lawyer.lawsuits" :key="l.id"><a :href="`/lawsuit/${l.id}`">{{ l.people.name }}</a></li>
				</ul>
			</div>
		</div>
		<Footer :loading="loading" />
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading: false,
			isOpening: false
		}
	},
	props: {
		lawyer: {
			type: Object,
			required: true
		}
	},
	mounted() {
		console.log(this.lawyer)
	}
}
</script>
