<template>
	<footer class="modal-card-foot">
        <b-button class="is-rounded is-outlined is-danger" @click="closeModal()">{{ $t('buttons.close') }}</b-button>
        <b-button native-type="submit" class="is-rounded is-primary" :loading="loading" @click="handleClick">{{ $t('buttons.save') }}</b-button>
    </footer>
</template>

<script>
export default {
	name: 'ModalFooter',
	props: {
		loading: {
			type: Boolean,
			required: false
		},
		action: {
			required: false
		},
		lawyer: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	methods: {
		closeModal () {
			let modal = this.$parent.$parent

			if (this.lawyer) {
				modal = this.$parent.$parent.$parent
			}

			if(!modal.$el.classList.contains('modal')){
				modal = modal.$parent
			}
			modal.close()
		},
		handleClick () {
			this.action ? this.action() : false
		}
	}
}
</script>
