<template>
	<Layout>
		<Header>
			<!-- <b-button v-if="permissionEnabled('users', 'create')" type="is-secondary create" rounded @click="modalCreate($event)">
				<svg-icon icon="plus" class="icon is-small"></svg-icon>
				<span>{{ $t('buttons.add') }}</span>
			</b-button> -->
		</Header>
		<div class="back-link">
			<a href="/lawsuits">
				<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
					<path d="M10.1,23a1,1,0,0,0,0-1.41L5.5,17H29.05a1,1,0,0,0,0-2H5.53l4.57-4.57A1,1,0,0,0,8.68,9L2.32,15.37a.9.9,0,0,0,0,1.27L8.68,23A1,1,0,0,0,10.1,23Z" />
				</svg>
				<span>{{ $t('buttons.back') }}</span>
			</a>
		</div>
		<Error v-if="errored" :icon="true" :back="true" />
		<Results v-if="!lawsuit && !loading" />
		<Placeholder v-if="loading" />
		<div v-if="lawsuit" class="filtering columns is-multiline is-mobile">
			<div class="column is-12-mobile is-12-tablet is-12-desktop is-5-widescreen">
				<article class="block no-hover full">
					<div class="column is-flex is-8 pr-0">
						<div class="block__logo">
							<img :src="lawsuit.client.logo_url" :alt="lawsuit.people.name" />
						</div>
						<div class="block__content pt-1">
							<h3 class="block__name link">{{ lawsuit.people.name }}</h3>
							<p class="block__email">{{ format(lawsuit.created_at) }} • {{ timeTo(lawsuit.created_at) }}</p>
						</div>
					</div>
					<div class="column is-4 pr-0">
						<div class="block__content is-align-items-center p-0">
							<h4 class="block__label">{{ $t('labels.status') }}</h4>
							<p :class="`block__value ${processStatusColor(lawsuit.status)}`">{{ processStatusText(lawsuit.status) }}</p>
						</div>
					</div>
				</article>
			</div>
			<div class="column is-12-mobile is-12-tablet is-12-desktop is-7-widescreen">
				<div class="columns is-multiline is-mobile">
					<div class="column">
						<div class="stats">
							<p class="stats__value">
								<span>{{ lawsuit.created_at | diffDays }}</span>
								<span class="stats__name"> dia(s)</span>
							</p>
							<p class="stats__name">desde o início</p>
						</div>
					</div>
					<div v-if="lawsuit.lawyer_id" class="column">
						<div class="stats">
							<p class="stats__value">
								<span>{{ lawsuit.date_lawyer | diffDays }}</span>
								<span class="stats__name"> dia(s)</span>
							</p>
							<p class="stats__name">com o advogado</p>
						</div>
					</div>
					<div class="column">
						<div class="stats">
							<p class="stats__value">
								<span>{{ lawsuit.date_last_change | diffDays }}</span>
								<span class="stats__name"> dia(s)</span>
							</p>
							<p class="stats__name">da última interação</p>
						</div>
					</div>
					<div class="column">
						<div class="stats">
							<p class="stats__value">
								<span>{{ lawsuit.comments_count }}</span>
							</p>
							<p class="stats__name">comentários</p>
						</div>
					</div>
					<div class="column">
						<div class="stats">
							<p class="stats__value">
								<span>{{ lawsuit.files_count }}</span>
							</p>
							<p class="stats__name">arquivos</p>
						</div>
					</div>
				</div>
			</div>
			<div class="column is-12 lawsuit-tabs">
				<b-tabs type="is-boxed" v-model="activeTab">
					<b-tab-item :label="$t('labels.initial_data')">
						<div class="columns">
							<div class="column is-12-mobile is-12-desktop is-9-widescreen">
								<h4 class="data-title">{{ $t('labels.seg_data') }}</h4>
								<ul class="is-flex data-list">
									<li>
										<h6>{{ $tc('fields.name', 1) }}</h6>
										<p>{{ lawsuit.people.name }}</p>
									</li>
									<li>
										<h6>{{ $t('fields.document') }}</h6>
										<p>{{ lawsuit.people.document }}</p>
									</li>
									<li>
										<h6>{{ $t('fields.birthdate') }}</h6>
										<p>{{ dateEnBr(lawsuit.people.birthdate) }}</p>
									</li>
									<li>
										<h6>{{ $tc('fields.deathdate') }}</h6>
										<p>{{ dateEnBr(lawsuit.people.date_death) }}</p>
									</li>
								</ul>
								<hr />
								<h4 class="data-title">{{ $t('labels.inv_data') }}</h4>
								<ul class="is-flex data-list">
									<li>
										<h6>{{ $t('labels.inv_name') }}</h6>
										<p>{{ lawsuit.lawsuit_heir.name }}</p>
									</li>
									<li>
										<h6>{{ $t('fields.email') }}</h6>
										<p>{{ lawsuit.lawsuit_heir.email }}</p>
									</li>
									<li>
										<h6>{{ $t('fields.phone') }}</h6>
										<p>{{ lawsuit.lawsuit_heir.phone }}</p>
									</li>
								</ul>
								<ul class="is-flex data-list">
									<li v-if="lawsuit.lawsuit_heir" class="full">
										<h6>{{ $t('labels.address') }}</h6>
										<p>{{ lawsuit.lawsuit_heir.address }}</p>
									</li>
									<li class="full">
										<h6>{{ $t('labels.process') }}</h6>
										<p>{{ format(lawsuit.created_at) }} às {{ hour(lawsuit.created_at) }}</p>
									</li>
								</ul>
							</div>
							<div class="column is-12-mobile is-12-desktop is-3-widescreen">
								<div class="data-action">
									<h6>{{ $tc('menu.lawyers', 1) }}</h6>
									<a v-if="lawsuit.lawyer" @click="modalLawyers(lawsuit.id)" href="#">{{ lawsuit.lawyer.user.full_name }}</a>
									<a v-else @click="modalLawyers(lawsuit.id)" href="#">Definir</a>
								</div>
								<div class="data-action">
									<h6>{{ $tc('fields.type', 1) }}</h6>
									<div class="control">
										<span class="select is-medium">
											<select id="status" name="status" v-model="lawsuit.type" @change="onChangeType($event)" :disabled="disabledToUser">
												<option selected disabled>{{ $t('labels.select') }}</option>
												<option v-for="t in processTypes()" :key="t.id" :value="t.id">{{ t.name }}</option>
											</select>
										</span>
									</div>
								</div>
								<div class="data-action">
									<h6>{{ $t('labels.status') }}</h6>
									<div class="control">
										<span class="select is-medium">
											<select id="status" name="status" v-model="lawsuit.status" @change="onChangeStatus($event)" :disabled="disabledToUser">
												<option selected disabled>{{ $t('labels.select') }}</option>
												<option v-for="s in processStatus()" :class="s.style" :key="s.id" :value="s.id">{{ s.name }}</option>
											</select>
										</span>
									</div>
								</div>
							</div>
						</div>
					</b-tab-item>
					<b-tab-item active :label="$tc('labels.files', 2)">
						<input id="fileUpload" type="file" @change="uploadFile" hidden>
						<ul v-if="lawsuit && lawsuit.files.length > 0" class="tab-files">
							<li v-for="f in lawsuit.files" :key="f.id">
								<a :href="f.path" target="_blank">
									<span>{{ f.name }}</span>
									<span class="type" :class="f.ext">{{ f.ext }}</span>
								</a>
							</li>
							<li>
								<button @click="openUploadFile()" :disabled="disabledToUser">
									<span>Adicionar</span>
								</button>
							</li>
						</ul>
						<div v-else class="no-files">
							<p class="mb-5">Desculpe, este processo ainda não possui arquivos.</p>
							<button class="button is-dark is-outlined is-rounded" @click="openUploadFile()" :disabled="disabledToUser">Adicionar</button>
						</div>
					</b-tab-item>
					<b-tab-item :label="$tc('labels.historic', 1)">
						<ol class="comments">
							<li class="comments-item">
								<span class="timeline-item-icon avatar-icon">
									<i class="avatar">
										<img src="https://assets.codepen.io/285131/hat-man.png" />
									</i>
								</span>
								<div class="new-comment">
									<textarea col="6" placeholder="Comentar..." v-model="comment" :disabled="disabledToUser"></textarea>
									<button class="button is-primary is-rounded" @click="sendComment" :disabled="disabledToUser">Enviar</button>
								</div>
							</li>
              <template v-if="lawsuit && lawsuit.historic.length > 0">
                <li v-for="c in lawsuit.historic" :key="c.id" class="comments-item">
                  <span class="timeline-item-icon faded-icon" :class="`timeline-comment`">
                    <!-- <svg-icon icon="timeline-comment" class="icon"></svg-icon> -->
                  </span>
                  <div class="timeline-item-description">
                    <span>
                      <strong v-if="c.type == 'start'">Início do processo </strong>
                      <strong v-if="c.user">{{ c.user.full_name }} </strong>
                      <span>{{ c.type | filterType }} </span>
                      <span v-if="c.lawsuit_file"><strong>{{ c.lawsuit_file.name }} </strong></span>
                      <span>dia {{ format(c.created_at) }} às {{ hour(c.created_at) }}.</span>
                      <div class="comment" v-if="c.lawsuit_comment">{{ c.lawsuit_comment.comment }}</div>
                    </span>
                  </div>
                </li>
              </template>
						</ol>
					</b-tab-item>
				</b-tabs>
			</div>
		</div>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
// import Icon from '@/components/Icon'
import Placeholder from '@/components/placeholders/Lawsuit'
import Error from '@/components/Error'
import Results from '@/components/Results'
import Modal from '@/components/modals/Lawyer'
import Lawyers from '@/components/modals/Lawyers'
import Api from '@/services/api'
import { create } from '@/mixins/modal'
import { successToast, errorToast } from '@/mixins/toast'
import { mapState, mapGetters } from 'vuex'
import mixinApp from '@/mixins/app'
import '@/mixins/generic'
import moment from 'moment'
import eventHub from '@/services/eventHub'

export default {
	name: 'lawsuitsPage',
	components: {
		Layout,
		Header,
		Placeholder,
		Error,
    // 'svg-icon': Icon,
		Results
	},
	mixins: [
		mixinApp
	],
	data() {
		return {
			lawsuitId: null,
			activeTab: 0,
			lawsuit: null,
			loading: true,
			errored: false,
			permission: [],
      comment: ''
		}
	},
	mounted() {
		if (this.$route.params && this.$route.params.id) {
			this.lawsuitId = this.$route.params.id
			this.getLawsuit()
		}

    eventHub.$on('set-lawyer-lawsuit', obj => {
			Api.post(`lawsuits/setLawyer`, { lawsuit_id: obj.lawsuitId, lawyer_id: obj.laweyrId })
				.then(() => {
					successToast('Selecionado com sucesso')
					this.getLawsuit()
				})
				.catch(() => {
					errorToast('Erro ao selecionar o advogado')
				})
		})
	},
	computed: {
		...mapGetters('user', [
			'permissionEnabled'
		]),
    ...mapState('user', [
      'user'
    ]),
    disabledToUser () {
      return !this.user || this.user.permission_id == this.permissionUser
    }
	},
	methods: {
		async getLawsuit() {
			this.loading = true
			try {
				const response = await Api.get(`lawsuits/findById/${this.lawsuitId}`)
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.lawsuit = data
					this.loading = false
				}
			} catch (e) {
				console.log(e)
			}
		},
		modalCreate() {
			if (this.permissionEnabled('lawsuits', 'create')) {
				create(this, 'lawsuits', Modal, 'New')
			}
		},
		modalLawyers(id) {
      if (this.user && this.user.permission_id != this.permissionUser) {
        this.$buefy.modal.open({
          parent: this,
          component: Lawyers,
          scroll: 'clip',
          customClass: 'is-lawyer',
          trapFocus: true,
          props: {
            lawsuitId: id
          }
        })
      }
		},
    openUploadFile() {
      document.getElementById("fileUpload").click()
    },
    uploadFile(e) {
      const file = e.target.files[0]

      if (file) {
        const formData = new FormData()

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }

        formData.append('file', file)
        formData.append('lawsuit_id', this.lawsuitId)

        this.loading = true
        Api.post('lawsuits/uploadFile', formData, config)
          .then(({ data }) => {
            successToast('Arquivo adicionado com sucesso')
            this.lawsuit.files.push(data)
          })
          .catch(() => {
            errorToast('Erro ao enviar o arquivo')
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    sendComment() {
      if (this.comment) {
        this.loading = true
        Api.post('lawsuits/comment', { lawsuit_id: this.lawsuitId, comment: this.comment })
          .then(({ data }) => {
            // arr.splice(0, 0, data)
            console.log(data)
            successToast('Comentário adicionado com sucesso')
          })
          .catch(() => {
            errorToast('Erro ao enviar o arquivo')
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        errorToast('Insira o comentário')
      }
    },
    onChangeType (e) {
      this.loading = true
      Api.post('lawsuits/updateType', { lawsuit_id: this.lawsuitId, type: e.target.value })
        .then(() => {
          successToast('Tipo de processo alterado com sucesso')
          this.getLawsuit()
        })
        .catch(() => {
          this.loading = false
          errorToast('Erro ao alterar o tipo de processo')
        })
    },
    onChangeStatus (e) {
      this.loading = true
      Api.post('lawsuits/updateStatus', { lawsuit_id: this.lawsuitId, status: e.target.value })
        .then(() => {
          successToast('Status do processo alterado com sucesso')
          this.getLawsuit()
        })
        .catch(() => {
          this.loading = false
          errorToast('Erro ao alterar o Status de processo')
        })
    }
	},
	filters: {
		diffDays(v) {
			return moment().diff(moment(v), 'days')
		},
    filterType(v) {
      const status = ['comment', 'update_status', 'add_file', 'add_lawyer', 'update_type']

      if (status.includes(v)) {
        const statusText = {
          // 'start': '',
          'comment': 'comentou',
          'update_status': 'atualizou o status',
          'add_file': 'adicionou o arquivo',
          'add_lawyer': 'vinculou o advogado',
          'update_type': 'atualizou o tipo'
        }

        return statusText[v]
      }

      return ''
    }
	},
  destroyed() {
		eventHub.$off('set-lawyer-lawsuit')
	}
}
</script>
