// import i18n from '@/language/i18n'
import auth from '@/middleware/auth'

export default [
	{
		path: '/dashboard',
		name: 'Dashboard',
		icon: 'dashboard',
		meta: {
			title: 'Dashboard'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Dashboard')
	},
	{
		path: '/roles/:page?/:id?',
		name: 'Roles',
		icon: 'roles',
		meta: {
			title: 'menu.roles'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Roles')
	},
	{
		path: '/users/:page?/:id?',
		name: 'Users',
		icon: 'users',
		meta: {
			title: 'menu.users'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Users')
	},
	{
		path: '/clients/:page?/:id?',
		name: 'Clients',
		icon: 'companies',
		meta: {
			title: 'menu.customers'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Clients')
	},
	{
		path: '/people/:page?/:id?',
		name: 'People',
		icon: 'people',
		meta: {
			title: 'menu.people'
		},
		beforeEnter: auth,
		component: () => import('@/pages/People')
	},
	{
		path: '/lawsuits/:page?/:id?',
		name: 'Lawsuits',
		icon: 'lawsuits',
		meta: {
			title: 'menu.lawsuits'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Lawsuits')
	},
	{
		path: '/lawsuit/:id?',
		name: 'Lawsuit',
		icon: 'lawsuits',
		meta: {
			title: 'menu.lawsuits'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Lawsuit')
	},
	{
		path: '/lawyers/:page?/:id?',
		name: 'Lawyers',
		icon: 'lawyers',
		meta: {
			title: 'menu.lawyers'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Lawyers')
	},
	// {
	// 	path: '/reports',
	// 	name: 'Reports',
	// 	icon: 'reports',
	// 	meta: {
	// 		title: 'menu.reports'
	// 	},
	// 	beforeEnter: auth,
	// 	component: () => import('@/pages/Reports')
	// },
	/* auto-gerado */
	{
		path: '/settings',
		name: 'Settings',
		icon: 'settings',
		meta: {
			title: 'menu.settings'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Settings')
	}
]
