<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form @submit.prevent="handleSubmit(saveRole)">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span translate="no">{{ $t(modalTitle(name)) }} <strong>{{ $tc('menu.roles') }}</strong></span>
					<span v-if="permission.id">#{{ permission.id }}</span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="modal-card">
					<div class="columns is-multiline is-mobile mb-2">
						<div class="column is-12-mobile is-4-tablet">
							<InputWithValidation rules="required" :label="$tc('fields.name')" v-model="permission.name" :disabled="name == 'Edit'" />
						</div>
						<div class="column is-12-mobile is-7-tablet">
							<InputWithValidation rules="required" :label="$t('fields.description')" v-model="permission.description" />
						</div>
						<div class="column is-12-mobile is-1-tablet">
							<b-field :label="$tc('fields.color', 1)" class="mb-2">
								<v-swatches v-model="permission.color" :swatches="swatches" row-length="5" popover-x="left" show-fallback fallback-input-type="color"></v-swatches>
							</b-field>
						</div>
					</div>
					<h3 class="is-size-5 is-semibold has-text-primary">{{ $tc('labels.permission', permission.route.length) }}</h3>
					<hr class="my-3" />
					<div class="columns is-multiline is-mobile">
						<div class="column is-hidden-mobile is-4-tablet"></div>
						<div v-for="(role, i) in crud" :key="i" class="column is-2-tablet text-center">
							<span class="is-size-7 text-uppercase is-semibold action" :class="false" @click="selectAll(role.label)">{{ $tc('labels.' + role.label, 0) }}</span>
						</div>
					</div>
					<span class="item" v-for="(r, i) in permission.route" :key="i">
						<div class="columns is-multiline is-mobile mb-0">
							<div class="column is-12-mobile is-4-tablet">
								<h4 class="is-size-6 is-semibold has-text-primary" translate="no">{{ $tc(`menu.${r.slug}`, 2) }}</h4>
							</div>
							<div class="column is-2-tablet text-center" v-for="c in crud" :key="c.label">
								<b-checkbox type="is-secondary" v-model="r.role[c.label]" :name="r.name.toLowerCase() + '_' + c.label" class="ml-4"></b-checkbox>
								<input type="hidden" :value="r.slug" />
							</div>
						</div>
						<hr class="mt-0 mb-4" />
					</span>

					<small class="modal-updated" v-if="permission.updated_at">{{ $t('labels.last_change') }} {{ format(permission.updated_at) }}</small>
				</div>
			</div>
			<Footer :loading="loading" />
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import VSwatches from 'vue-swatches'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import Footer from '@/components/modals/Footer'
import '@/mixins/generic'

export default {
	components: {
		Footer,
		InputWithValidation,
		ValidationObserver,
		VSwatches
	},
	props: {
		id: {
			type: [Number, String],
			required: false
		},
		name: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			swatches: this.defaultColors(),
			crud: [
				{
					label: 'edit'
				},
				{
					label: 'read'
				},
				{
					label: 'create'
				},
				{
					label: 'delete'
				}
			],
			permission: {
				name: '',
				description: '',
				color: '',
				active: true,
				route: []
			}
		}
	},
	methods: {
		async selectAll(role) {
			let c = role.toLowerCase()

			this.permission.route.forEach(r => {
				r.role[c] = !r.role[c]
			})
		},
		async saveRole() {
			this.name === 'New' ? await this.store() : await this.update()
		},
		async store() {
			try {
				this.loading = true
				const response = await Api.post('permission/store', this.permission)
				const { status } = response
				if (status === 201 || status === 200) {
					// const { message } = response.data
					this.$emit('close')
					history.pushState({}, '', '/roles')
					successToast(this.$t('alerts.create.success', [this.$tc('menu.roles')]))
					eventHub.$emit('reload-roles')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					// const { message } = e.data
					errorToast(this.$t('alerts.create.error', [this.$tc('menu.roles')]))
				}
			} finally {
				this.loading = false
			}
		},
		async update() {
			try {
				this.loading = true
				const response = await Api.put(`permission/update/${this.id}`, this.permission)
				const { status } = response
				if ([200, 201].includes(status)) {
					this.$emit('close')
					history.pushState({}, '', '/roles')
					successToast(this.$t('alerts.update.success', [this.$tc('menu.roles')]))
					eventHub.$emit('reload-roles')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					// const { message } = e.data
					errorToast(this.$t('alerts.update.error', [this.$tc('menu.roles')]))
				}
			} finally {
				this.loading = false
			}
		},
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`permission/findById/${this.id}`)
					const { status } = response
					if (status === 200) {
						const { data } = response
						this.permission = data
						this.permission.route = JSON.parse(data.route)
						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}
			}
			else {
				this.populatePermissionRoute();
			}
		},
		populatePermissionRoute() {
			var menus = [
				'Dashboard',
				'Users',
				'Roles'
			];

			for (var i in menus) {
				if (!Array.isArray(this.permission.route)) continue

				this.permission.route.push({
					name: menus[i],
					role: {
						edit: false,
						read: false,
						create: false,
						delete: false,
					},
					slug: menus[i].toLowerCase()
				})
			}
		}
	},
	mounted() {
		this.findById()
	}
}
</script>
