<template>
	<article class="column is-12-mobile is-one-third-desktop">
		<ul class="dashboard__panel dashboard__list">
			<li class="dashboard__list__item" v-for="u in users" :key="u.id">
				<span class="dashboard__list__name">
					<span class="is-ellipsis is-capitalized is-semibold">{{ u.people.name }}</span>
					<span class="status-process judicial" title="Processo Judicial"></span>
				</span>
				<span class="dashboard__list__date logo">
					<img :src="u.client.logo_url" :alt="u.people.name">
				</span>
			</li>
		</ul>
	</article>
</template>

<script>
import Api from '@/services/api'
import '@/mixins/date'

export default {
	name: 'Users',
	data() {
		return {
			users: []
		}
	},
	mounted() {
		this.getUsers()
	},
	methods: {
		getUsers() {
			Api.get('lawsuits/getLawsuitsDahboard')
				.then(({ data }) => {
					this.users = data
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}
}
</script>
