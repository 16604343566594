<template>
	<div class="dashboard__panel columns is-multiline big-numbers">
		<h3 class="title">Como estão os números?</h3>
		<div class="column is-12-mobile numbers bordered">
			<h5 class="has-text-danger">0,8%</h5>
			<p>Taxa Mensal <br>de Sinistro</p>
		</div>
		<div class="column is-12-mobile numbers bordered">
			<h5 class="has-text-warning">0,2%</h5>
			<p>Taxa Anual <br>de Sinistro</p>
		</div>
		<div class="column is-12-mobile numbers">
			<h5 class="has-text-success">7.492</h5>
			<p>Média de Vidas <br>por Empresa</p>
		</div>
	</div>
</template>

<script>
import '@/mixins/date'

export default {
	name: 'BigNumbers'
}
</script>
