<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form autocomplete="off" ref="form" @submit.prevent="handleSubmit(saveLawyer)">
			<header v-if="!publicPage" class="modal-card-head">
				<h4 class="modal-card-title">
					<span>
						{{ $t(modalTitle(name)) }}
						<strong>{{ $tc('menu.lawyers') }}</strong>
					</span>
					<b-button v-if="laywer.id && !laywer.approved" class="is-rounded is-info approve-button" :loading="loading" @click="approvedUser">{{ $t('labels.approve') }}</b-button>
				</h4>
			</header>
			<div :class="publicPage ? '' : 'modal-card-body'">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div :class="publicPage ? '' : 'modal-card mb-3'">
					<h3 class="is-size-5 is-semibold has-text-primary">{{ $t('labels.personal_data') }}</h3>
					<hr class="my-3" />
					<div class="columns is-multiline">
						<div :class="publicPage ? 'column is-12' : 'column is-6'">
							<InputWithValidation name="name" rules="required" :label="$t('fields.full_name')" v-model="laywer.name" />
						</div>
						<div v-if="!publicPage" class="column is-6">
							<InputWithValidation name="email" rules="required|email" :label="$t('fields.email')" v-model="laywer.email" />
						</div>
						<div v-if="publicPage" class="column is-6">
							<InputWithValidation name="email" rules="required|email" :label="$t('fields.email')" v-model="laywer.email" />
						</div>
            <div v-if="publicPage" class="column is-6">
							<InputWithValidation type="password" name="password" rules="required|min:8" :label="$t('fields.password')" password-reveal v-model="laywer.password" />
						</div>
						<div class="column is-6">
							<InputWithValidation name="document" rules="required|min:14" v-mask="'###.###.###-##'" :label="$t('fields.document')" v-model="laywer.document" />
						</div>
						<div class="column is-6">
							<InputWithValidation name="date" rules="required|min:10" v-mask="'##/##/####'" :label="$t('fields.birthdate')" v-model="laywer.birth_date" />
						</div>
						<div class="column is-12">
							<InputWithValidation rules="required" field="textarea" placeholder="Resuma sua experiência em poucas frases" :label="$t('labels.bio')" v-model="laywer.bio" />
						</div>
						<div class="column is-6">
							<InputWithValidation name="phone" rules="required|min:14" v-mask="'(##) ####-####'" :label="$t('fields.phone')" v-model="laywer.phone" />
						</div>
						<div class="column is-6">
							<InputWithValidation name="cel" rules="required|min:15" v-mask="'(##) #####-####'" :label="$t('fields.cel')" v-model="laywer.celphone" />
						</div>
						<div class="column is-12">
							<SelectMultiple :input_label="$t('fields.contact_type')" rules="required" v-model="laywer.contacts" :options="contacts" track-by="name" label="name" />
						</div>
					</div>
					<h3 class="is-size-5 is-semibold has-text-primary">{{ $tc('menu.roles') }}</h3>
					<hr class="my-3" />
					<div class="columns is-multiline">
						<div class="column is-6">
							<SelectWithValidation :label="$t('fields.genre')" rules="required" v-model="laywer.gender_type_id">
								<option v-for="g in genres" :value="g.id" :key="g.id">{{ g.name }}</option>
							</SelectWithValidation>
						</div>
						<div class="column is-6">
							<SelectWithValidation :label="$t('fields.orientation')" rules="required" v-model="laywer.orientation_type_id">
								<option v-for="o in orientation" :value="o.id" :key="o.id">{{ o.name }}</option>
							</SelectWithValidation>
						</div>
						<div class="column is-6">
							<SelectWithValidation :label="$t('fields.race')" rules="required" v-model="laywer.race_type_id">
								<option v-for="r in race" :value="r.id" :key="r.id">{{ r.name }}</option>
							</SelectWithValidation>
							<small><a class="is-semibold has-text-grey-light mt-2" href="https://educa.ibge.gov.br/jovens/conheca-o-brasil/populacao/18319-cor-ou-raca.html" target="_blank">Conforme a base de autodeclaração do IBGE.</a></small>
						</div>
						<div class="column is-6">
							<SelectWithValidation :label="$t('fields.religion')" rules="required" v-model="laywer.religion_type_id">
								<option v-for="r in religion" :value="r.id" :key="r.id">{{ r.name }}</option>
							</SelectWithValidation>
						</div>
					</div>
					<h3 class="is-size-5 is-semibold has-text-primary">{{ $t('labels.profissional_data') }}</h3>
					<hr class="my-3" />
					<div class="columns is-multiline">
						<div class="column is-6">
							<InputWithValidation name="oab" rules="required" :label="$t('labels.oab')" v-model="laywer.oab" />
						</div>
						<div class="column is-6">
							<SelectMultiple :input_label="$t('labels.seccional')" rules="required" v-model="laywer.seccionais" :options="states" track-by="name" label="name" />
						</div>
						<div class="column is-6">
							<InputWithValidation name="since" rules="required|min:4" v-mask="'####'" :label="$t('labels.since')" v-model="laywer.since" />
						</div>
						<div class="column is-6">
							<SelectMultiple :input_label="$t('labels.training')" rules="required" v-model="laywer.trainings" :options="training" track-by="name" label="name" />
						</div>
						<div class="column is-6">
							<SelectMultiple :input_label="$t('labels.states')" rules="required" v-model="laywer.states" :options="states" track-by="name" label="name" />
						</div>
						<div class="column is-6">
							<SelectMultiple :input_label="$t('labels.areas')" rules="required" v-model="laywer.areas" :options="areas" track-by="name" label="name" />
						</div>
						<div class="column is-12">
							<InputWithValidation name="office" rules="required" :label="$t('labels.office')" v-model="laywer.office" />
						</div>
						<div class="column is-12">
							<InputWithValidation rules="required" field="textarea" placeholder="Coloque aqui alguns links profissionais como sites, Linkedin, Facebook, Instagram e etc." :label="$t('labels.reference')" v-model="laywer.reference" />
						</div>
					</div>
					<h3 class="is-size-5 is-semibold has-text-primary">{{ $t('labels.address') }}</h3>
					<hr class="my-3" />
					<div class="columns is-multiline pb-5 mb-5">
						<div class="column is-3">
							<InputWithValidation name="cep" rules="required|min:9" v-mask="'#####-###'" :label="$t('fields.cep')" v-model="laywer.cep" :blur="setAddress" />
						</div>
						<div class="column is-7">
							<InputWithValidation name="street" rules="required" :label="$t('labels.street')" v-model="laywer.street" />
						</div>
						<div class="column is-2">
							<InputWithValidation name="number" rules="required" :label="$t('labels.number')" v-model="laywer.number" />
						</div>
						<div class="column is-3">
							<InputWithValidation name="extra" :label="$t('labels.extra_address')" v-model="laywer.extra" />
						</div>
						<div class="column is-3">
							<InputWithValidation name="neighborhood" rules="required" :label="$t('labels.neighborhood')" v-model="laywer.neighborhood" />
						</div>
						<div class="column is-3">
							<InputWithValidation name="city" rules="required" :label="$t('labels.city')" v-model="laywer.city" />
						</div>
						<div class="column is-3">
							<SelectWithValidation :label="$t('labels.state')" rules="required" v-model="laywer.state">
								<option v-for="s in states" :value="s.uf" :key="s.id">{{ s.name }}</option>
							</SelectWithValidation>
						</div>
					</div>
					<small class="modal-updated" v-if="laywer.updated_at">{{ $t('labels.last_change') }} {{ format(laywer.updated_at) }}</small>
				</div>
			</div>
			<Footer v-if="!publicPage" :loading="loading" :lawyer="true" />

      <h3 v-if="publicPage" class="is-size-5 is-semibold has-text-primary">Termos de Aceite</h3>
			<hr v-if="publicPage" class="my-3" />
			<div v-if="publicPage" class="terms">
				<p><strong>BENEFÍCIO LEGAL PARTICIPAÇÕES E SERVIÇOS LTDA.</strong>, pessoa jurídica inscrita no CNPJ/MF sob o nº 28.532.610/0001-55, com sede na Cidade de São Paulo, Estado de São Paulo, na Avenida Rebouças, 353, 2º andar, CEP 05401-000, neste ato representada nos termos de seu contrato social, doravante denominada “Benefício Legal®” CONSIDERANDO QUE:</p>
				<ol>
					<li>a Benefício Legal® é uma empresa prestadora de serviços de assistência, voltada à democratização do acesso à Justiça e valorização da advocacia que, dentre outros serviços, presta o serviço de assistência denominado “Assistência Maria” relacionado à prevenção e combate à violência doméstica;</li>
					<li>a Assistência Maria disponibiliza às suas usuárias, dentre outras aplicações e funcionalidades, a possibilidade de (a) criação de uma Rede de Proteção; (b) acionamento da função AJUDA para informar seus protetores; (c) localização de serviços públicos e privados voltados ao atendimento de casos de violência doméstica e que estejam próximos da usuária; (d) acesso ao banco de dados de perguntas mais frequentes sobre violência doméstica; (e) recebimento de indicação de advogadas(os) especializadas(os) em violência doméstica conforme sua solicitação e preferências.</li>
					<li>a Assistência Maria é operacionalizada por meio de plataforma digital e serviço de suporte mantido pela Benefício Legal® (“Plataforma”), interface por meio da qual os serviços podem ser prestados com segurança, escalabilidade e eficiência;</li>
					<li>a(o) Advogada(o) Parceira(o) é membro devidamente inscrito na Ordem dos Advogados do Brasil, autorizado e habilitado à prática da advocacia no Brasil, e tem interesse em se credenciar junto à Benefício Legal® para atendimento de usuárias da Plataforma.</li>
				</ol>
				<h4>CLÁUSULA PRIMEIRA – DO OBJETO</h4>
				<ul>
					<li>1.1 O presente Termo tem por objeto credenciar a(o) Advogada(o) Parceira(o) à Benefício Legal® para, por meio da Plataforma, ter seu perfil apresentado às usuárias que solicitarem a indicação de profissionais e suas características sejam compatíveis com as desejadas pelas solicitantes.</li>
					<li>1.2. Uma vez credenciado, conforme este Termo, a(o) Advogada(o) Parceira(o) passará a integrar a lista de Advogadas(os) Credenciadas(os).</li>
					<li>1.3. A(O) Advogada(o) Parceira(o), desde já, concorda que os honorários pelos eventuais serviços prestados relacionados ao objeto do presente Termo serão pagos diretamente pela usuária final da Assistência Maria, conforme estabelecido na Cláusula Terceira – Remuneração deste Termo, sendo vedado ao Advogado Parceiro cobrar, exigir ou requerer qualquer tipo de honorário, remuneração ou compensação diretamente da Benefício Legal®.</li>
					<li>1.4. Nada neste instrumento deverá ser interpretado de modo a impedir, embaraçar ou condicionar a prestação de serviços advocatícios pela(o) Advogada(o) Parceira(o) às usuárias ou a quem quer que o contrate.</li>
					<li>1.5. As usuárias possuem total autonomia para escolherem entre as(os) Advogadas(os) Parceiras(os) credenciados para assessorá-las ou representá-las. Dessa forma, a Benefício Legal® não garante qualquer volume de serviço para a(o) Advogada(o) Parceira(o).</li>
				</ul>
				<h4>CLÁUSULA SEGUNDA - OBRIGAÇÕES DAS PARTES</h4>
				<ul>
					<li>2.1. Sem prejuízo de outras obrigações constantes deste Termo, a(o) Advogada(o) Parceira(o) obriga-se a: prestar os serviços que vierem a ser contratados pelas usuárias, empregando seus melhores esforços para a sua conclusão satisfatória e em estrita observância aos preceitos éticos, e profissionais da atividade advocatícia, em consonância com o Estatuto da OAB (Lei nº 8.906, de 4 de julho de 1994) e com o Código de Ética e Disciplina da OAB; responder em prazo razoável as solicitações das usuárias relativas aos serviços para os quais tenham sido contratadas; utilizar a Plataforma, nos termos dos Termos de Uso da Plataforma constante do sítio eletrônico da www.assistenciamaria.com.br; e utilizar todas as informações pessoais das usuárias, obtidas em virtude da prestação de serviço, para os estritos fins da execução desse serviço, devendo mantê-las confidenciais todas, não as divulgando a terceiros, publicando ou utilizando-as em proveito próprio, sem a devida e prévia autorização formal da usuária.</li>
					<li>2.2. Sem prejuízo de outras obrigações constantes deste Termo, a Benefício Legal® obriga-se a:</li>
					<ul>
						<li>Credenciar e manter credenciada na base de dados de Advogada(o) Parceira(o);</li>
						<li>apresentar o perfil da Advogada(o) Parceira(o) conforme solicitação e preferências indicadas pelas usuárias, independentemente de qualquer viés ou características não especificadas, de modo a garantir a neutralidade da Plataforma e respeito aos critérios de busca das usuárias;</li>
						<li>informar à Advogada(o) Parceira(o) eventuais reclamações ou denúncia de violações desse Termos, permitindo-lhes o esclarecimento, defesa e contraditório;</li>
						<li>descadastrar a Advogada(o) Parceira(o) que violar os presentes Termos ou não estiver devidamente habilitada ao exercício da advocacia ou mediante solicitação da(o) própria(o) Advogada(o) Parceira(o).</li>
					</ul>
				</ul>
				<h4>CLÁUSULA TERCEIRA – REMUNERAÇÃO</h4>
				<p>Os serviços e honorários eventualmente pactuados entre a Advogada(o) Parceira(o) e usuárias da Plataforma são responsabilidade única e exclusiva das Partes diretamente envolvidas, não tendo a Benefício Legal® qualquer visibilidade, ingerência, participação a qualquer título ou responsabilidade subsidiária e deverão ser integralmente pagos pela usuária contratante de tais serviços.</p>
				<h4>CLÁUSULA QUARTA – VIGÊNCIA E RESILIÇÃO</h4>
				<ul>
					<li>4.1. O presente Termo entra em vigor na data de sua assinatura e permanecerá em vigor por prazo indeterminado.</li>
					<li>4.2. O presente Termo poderá ser resilido, com efeitos imediatos, por qualquer das Partes por meio do email maria@beneficiolegal.com.br. Caso a(o) Advogada(o) Parceira(o) venha a rescindir este Termo (descredenciando-se, portanto), a Benefício Legal® excluirá seu perfil em até 5 dias após a rescisão.</li>
				</ul>
				<h4>CLÁUSULA QUINTA – INDENIZAÇÃO</h4>
				<p>A(O) Advogada(o) Parceira(o) se compromete a proteger, indenizar e manter a Benefício Legal® isenta e a salvo de qualquer responsabilidade, perda, dano, custo, reclamação, processo ou procedimento judicial ou administrativo, tributo, multa, juro ou despesa sofrido, incorrido ou imputado à Benefício Legal®, pelas usuárias da Plataforma, em razão da falha, erro ou inadimplemento, independentemente de culpa, na prestação dos seus serviços.</p>
				<h4>CLÁUSULA SEXTA - DISPOSIÇÕES DIVERSAS</h4>
					<ul>
						<li>6.1. O presente Termo expressa a vontade integral das Partes, somente podendo ser alterado ou modificado por meio de instrumento escrito assinado por ambas as Partes.</li>
						<li>6.2. Nenhuma das Partes poderá transferir ou ceder os direitos e obrigações objeto deste Termo.</li>
						<li>6.3. Este Termo constitui o acordo integral entre as Partes acerca do conteúdo deste instrumento, substituindo todas as negociações, compromissos e entendimentos prévios, escritos ou orais, relacionados ao seu objeto.</li>
						<li>6.4 Nada neste instrumento poderá ser considerado ou interpretado pelas Partes, nem por terceiros, como a criação de uma relação de sociedade, prestação de serviços e/ou vínculo de emprego entre as Partes ou qualquer dos seus respectivos agentes e empregados.</li>
						<li>6.5 Todos os avisos ou comunicações exigidos ou permitidos por este Termo deverão ser feitos por escrito e entregues por e-mail com aviso de recebimento ou por meio da Plataforma.</li>
						<li>6.6. As Partes, neste ato, elegem o foro da Comarca da Capital do Estado de São Paulo para a solução de qualquer litígio decorrente do presente Termo, com a exclusão de qualquer outro, por mais privilegiado que seja.</li>
				</ul>
			</div>

      <div v-if="publicPage" class="is-flex">
        <b-field class="mt-5">
					<b-checkbox checked type="is-secondary">
						Eu li e concordo com os termos de uso.
					</b-checkbox>
        </b-field>
				<button class="button is-primary is-large is-rounded">Cadastrar</button>
			</div>
		</form>
	</ValidationObserver>
</template>

<script>
import axios from 'axios'
import InputWithValidation from '@/components/inputs/InputWithValidation'
import SelectMultiple from '@/components/inputs/SelectMultiple'
import SelectWithValidation from '@/components/inputs/SelectWithValidation'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast, errorLaravel } from '@/mixins/toast'
import Footer from '@/components/modals/Footer'
import '@/mixins/generic'

export default {
  name: 'LawyerInputs',
	components: {
		Footer,
		SelectMultiple,
		SelectWithValidation,
		InputWithValidation,
		ValidationObserver
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		},
    publicPage: {
      type: Boolean,
      required: false,
      default: false
    }
	},
	data() {
		return {
			laywer: {},
			isOpening: false,
			loading: false,
			role: 1,
			permission: [],
			visible: false,
      contacts: [],
			genres: [],
			orientation: [],
			race: [],
			religion: [],
			states: [],
      training: [],
			areas: []
		}
	},
	methods: {
    async getTypes() {
      try {
        const { status, data} = await Api.get(`app/getTypes`)
        if (status === 200) {
          this.areas = data.areasType
          this.contacts = data.contactType
          this.genres = data.genderType
          this.orientation = data.orientationType
          this.race = data.raceType
          this.religion = data.religionType
          this.states = data.stateType
          this.training = data.trainingType
        }
      } catch (e) {
        console.log(e)
      }
    },
    async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`lawyers/findById/${this.id}`)
					if (response.status === 200) {
						const { data } = response

            let lawyerTemp = data.lawyer
            delete lawyerTemp.id

            const lawyer = {
              id: data.id,
              name: data.full_name,
              birth_date: this.dateEnBr(data.birth_date),
              email: data.email,
			  approved: data.approved,
              ...lawyerTemp
            }

						this.laywer = lawyer
						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}
			}
		},
		async update() {
			try {
				this.loading = true
				const { status } = await Api.put(`lawyers/update/${this.id}`, this.laywer)
				if (status === 200 || status === 201) {
					this.$emit('close')
					history.pushState({}, '', '/laywers')
					successToast(this.$t('alerts.update.success', [this.$tc('menu.lawyers')]))
					eventHub.$emit('reload-lawyers')
				}
			} catch (e) {
				console.log(e)
        errorLaravel(e, 'Erro ao atualizar o(a) advogado(a)')
			} finally {
				this.loading = false
			}
		},
		async store() {
			try {
				this.loading = true
        let url = this.publicPage ? 'public/storeLawyer' : 'lawyers/store'
				const { status } = await Api.post(url, this.laywer)
				if (status === 200 || status === 201) {
					this.$emit('close')
					history.pushState({}, '', '/laywers')
					successToast(this.publicPage ? 'Registrado com sucesso' : this.$t('alerts.create.success', [this.$tc('menu.lawyers')]))
					eventHub.$emit('reload-lawyers')
				}
			} catch (e) {
				console.log(e)
        errorLaravel(e, 'Erro ao salvar o(a) advogado(a)')
			} finally {
				this.loading = false
			}
		},
		async saveLawyer() {
			this.name === 'New' ? await this.store() : await this.update()
		},
		setAddress () {
      if (this.laywer.cep) {
        axios.get(`https://viacep.com.br/ws/${this.laywer.cep}/json`)
          .then(({ data }) => {
            this.laywer.street = data.logradouro
            this.laywer.neighborhood = data.bairro
            this.laywer.city = data.localidade
            this.laywer.state = data.uf
          })
          .catch(error => {
            console.log('error', error)
          })
      }
		},
    async approvedUser() {
      if (this.loading) {
				return false
			}

			this.isOpening = true
			this.loading = true

			try {
				const response = await Api.post(`users/approve/${this.id}`)
				if (response.status === 200) {
					successToast(this.$t('alerts.approved.success'))
					eventHub.$emit('reload-lawyers')
				}
			} catch (e) {
				errorToast(this.$t('alerts.approved.error'))
			} finally {
				this.loading = false
				this.findById()
			}
    }
	},
	mounted() {
		this.getTypes()
		this.findById()
	}
}
</script>
