<template>
	<article class="block placeholder">
		<div class="block__logo">
			<b-skeleton width="130px" height="40px"></b-skeleton>
		</div>
		<div class="block__content">
			<span class="block__name">
				<b-skeleton width="65%" height="15"></b-skeleton>
				<b-skeleton width="35%" height="10"></b-skeleton>
			</span>
		</div>
	</article>
</template>

<script>
export default {
	name: 'Placeholder'
}
</script>
