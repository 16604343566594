<template>
	<div>
		<header class="modal-card-head">
			<h4 class="modal-card-title">
				<span>
					<span>{{ $tc('labels.pick') }} </span>
					<strong>{{ $tc('menu.lawyers') }}</strong>
				</span>
			</h4>
		</header>
		<div class="modal-card-body is-sm">
			<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
			<div class="modal-card mb-3">
				<b-field>
					<b-input v-model="nameLawyer" size="is-medium" name="search-lawyer" type="text" autocomplete="no" placeholder="Qual o nome do Advogado?"></b-input>
				</b-field>
				<ul class="list-laywers">
					<li v-for="l in laywers" :key="l.id">
						<span class="button-laywer">
							<span @click.self="selectLawyer(l.lawyer.id)" class="link">{{ l.full_name }}</span>
							<b-collapse :open="false">
								<template #trigger>
									<button @click="lawyerDetails()" class="arrow">
										<svg-icon icon="down" class="icon"></svg-icon>
									</button>
								</template>
								<div class="details columns is-multiline">
									<div class="column is-6">
										<p><small>Escritório</small> {{ l.lawyer.office }}</p>
									</div>
									<div class="column is-3">
										<p><small>Cidade</small> {{ l.lawyer.city }}</p>
									</div>
									<div class="column is-3">
										<p><small>Estado</small> {{ l.lawyer.state }}</p>
									</div>
									<a :href="`/lawyers/edit/${l.id}`" class="button" target="_blank">
										<span>Detalhes do Advogado</span>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
											<path d="M23.78,27H8.22A3.23,3.23,0,0,1,5,23.78V8.22A3.23,3.23,0,0,1,8.22,5h4.46V7H8.22A1.22,1.22,0,0,0,7,8.22V23.78A1.22,1.22,0,0,0,8.22,25H23.78A1.22,1.22,0,0,0,25,23.78V19.5h2v4.28A3.23,3.23,0,0,1,23.78,27Z"/>
											<path d="M27,14.89H25V7H17.11V5H25a2,2,0,0,1,2,2Z"/>
											<rect x="14.06" y="10.33" width="13.22" height="2" transform="translate(-1.95 17.94) rotate(-45)"/>
										</svg>
									</a>
								</div>
							</b-collapse>
						</span>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import Icon from '@/components/Icon'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import '@/mixins/generic'

export default {
	components: {
		'svg-icon': Icon
	},
	props: {
		lawsuitId: {
			type: Number,
			required: false
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			laywers: {},
			isOpening: false,
			loading: false,
			role: 1,
			permission: [],
			visible: false,
			nameLawyer: ''
		}
	},
	methods: {
		async getLawyers() {
			this.isOpening = true
			try {
				let url = 'lawyers/selectWithDetails'

				if (this.nameLawyer) {
					url += `?q=${this.nameLawyer}`
				}

				const response = await Api.get(url)
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.laywers = data
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.isOpening = false
			}
		},
		async lawyerDetails () {
			console.log('detalhes do adevogado!')
		},
		async selectLawyer(id) {
			eventHub.$emit('set-lawyer-lawsuit', { lawsuitId: this.lawsuitId, laweyrId: id })
			this.$emit('close')
		}
	},
	mounted() {
		this.getLawyers()
	},
	watch: {
		nameLawyer(v) {
			if(!v || v.length > 1) {
				this.getLawyers()
			}
		}
	}
}
</script>
