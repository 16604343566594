<template>
	<Layout>
		<Header>
			<b-button v-if="permissionEnabled('people', 'create')" type="is-secondary create" rounded @click="modalCreate($event)">
				<svg-icon icon="plus" class="icon is-small"></svg-icon>
				<span>{{ $t('buttons.add') }}</span>
			</b-button>
		</Header>
		<filter-default
			:filters="filters"
			@filterCompany="filterCompany"
			@filterSearch="filterSearch"
			enable-search
		/>
		<Error v-if="errored" :icon="true" :back="true" />
		<Results v-if="people.length == 0 && !loading" />
		<div v-if="loading" class="columns is-multiline">
			<div v-for="n in pagination" :key="n" class="column is-12-mobile is-6-tablet is-4-desktop">
				<Placeholder />
			</div>
		</div>
		<transition-group name="filtering" class="filtering columns is-multiline mb-5" tag="div">
			<div v-for="p in people" :key="p.id" class="column is-12-mobile is-6-tablet is-4-desktop">
				<article class="block">
					<div class="block__content">
						<h3 class="block__name link" @click.self="modalEdit(p.id)">{{ p.name }}</h3>
						<p class="block__email">{{ format(p.created_at) }} • {{ timeTo(p.created_at) }}</p>
					</div>
					<Trigger :id="p.id" page="people" />
				</article>
			</div>
		</transition-group>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Icon from '@/components/Icon'
import Placeholder from '@/components/placeholders/User'
import Trigger from '@/components/triggers/Global'
import Error from '@/components/Error'
import Results from '@/components/Results'
import Modal from '@/components/modals/Person'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { create, update } from '@/mixins/modal'
import { successToast, errorToast } from '@/mixins/toast'
import { mapState, mapGetters } from 'vuex'
import mixinApp from '@/mixins/app'
import FilterDefault from '@/components/FilterDefault'

export default {
	name: 'PeoplePage',
	components: {
		Layout,
		Header,
		Placeholder,
		Trigger,
		Error,
		Results,
		'svg-icon': Icon,
		FilterDefault
	},
	mixins: [
		mixinApp
	],
	data() {
		return {
			current: 1,
			page: 1,
			total: 0,
			pagination: 15,
			data: [],
			status: 1,
			loading: true,
			errored: false,
			permission: [],
			showPagination: false,
			filter: {
				name: '',
				client: null,
			},
			filters: []
		}
	},
	mounted() {
		this.getAllPeople()
		this.getClients()

		eventHub.$on('edit-modal-people', obj => {
			update(this, 'people', obj.id, Modal, 'Edit', obj.root)
		})
		eventHub.$on('delete-people', obj => {
			this.$buefy.dialog.alert({
				size: 'is-delete',
				type: 'is-outlined is-primary',
				title: this.$t('labels.warning'),
				message: '<span>' + this.$t('alerts.delete.confirmation') + '</span>',
				canCancel: true,
				focusOn: 'cancel',
				cancelText: this.$t('buttons.no'),
				confirmText: this.$t('buttons.yes'),
				onConfirm: async () => {
					try {
						const response = await Api.delete(`people/destroy/${obj.id}`)
						const { status } = response
						if (status === 200) {
							successToast(this.$t('alerts.delete.success_common'))
							await this.getAllPeople()
						} else {
							errorToast(this.$t('alerts.delete.error', [this.$tc('menu.people')]))
						}
					} catch (e) {
						if (e?.data?.message) {
							errorToast(e.data.message)
						}
					}
				}
			})
		})
		eventHub.$on('reload-people', () => {
			this.getAllPeople()
		})
	},
	computed: {
		people() {
			const url = window.location.href.split('/')
			const type = url.find(el => el === 'page')
			if (type === 'page') {
				let current = this.current - 1
				return this.data.slice(current * this.pagination, (current + 1) * this.pagination)
			} else {
				let current = this.current - 1
				return this.data.slice(current * this.pagination, (current + 1) * this.pagination)
			}
		},
		...mapGetters('user', [
			'permissionEnabled'
		]),
		...mapState('user', [
			'user'
		])
	},
	methods: {
		async getClients() {
            if (this.user && this.user.permission_id == this.permissionMaster) {
                try {
                    const { status, data } = await Api.get(`app/clientSelect`)
                    if (status === 200) {
                        let options = data.map(d => {
                            return {
                                label: d.name,
                                value: `${d.id}`
                            }
                        })

                        this.filters.push({
                            title: 'Cliente',
                            function: 'filterCompany',
                            options: options
                        })
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        },
		async getAllPeople() {
			try {
				const response = await Api.get('people/findAll')
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.data = data
					this.total = data.length
					this.showPagination = this.total > 15 ? true : false
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async filterSearch(v) {
			try {
				this.filter.name = v
				if (v.length > 0) {
					const response = await Api.post('people/findByName', {
						name: this.filter.name,
						client: this.filter.client
					})
					const { status } = response
					if (status === 200) {
						const { data } = response
						this.data = data
						this.total = data.length
					}
				} else {
					await this.getAllPeople()
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		modalCreate() {
			if (this.permissionEnabled('people', 'create')) {
				create(this, 'people', Modal, 'New')
			}
		},
		modalEdit(id) {
			if (this.permissionEnabled('people', 'edit')) {
				update(this, 'people', id, Modal, 'Edit')
			}
		},
		async filterCompany (v) {
            try {
				this.loading = true
				this.data = []
				this.filter.client = v
				const response = await Api.post('people/filterByClient', {
					name: this.filter.name,
					client: v
				})

				if (response.status === 200) {
					this.data = response.data
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		}
	},
  destroyed() {
		eventHub.$off('edit-modal-people')
		eventHub.$off('delete-people')
		eventHub.$off('reload-people')
	}
}
</script>
