<template>
	<b-dropdown class="block__dropdown" trigger="click" position="is-bottom-left">
		<svg-icon class="dots" slot="trigger" icon="dots"></svg-icon>
		<b-dropdown-item v-for="(l, i) in items" :key="i" :id="`action-${l.type}`" :class="l.color" @click="handleClick(l, id)">
			<svg-icon :icon="l.icon"></svg-icon>
			<span>{{ $t(l.name) }}</span>
		</b-dropdown-item>
	</b-dropdown>
</template>
<script>
import Icon from '@/components/Icon'
import eventHub from '@/services/eventHub'
import { mapGetters } from 'vuex'

export default {
	name: 'Trigger',
	components: {
		'svg-icon': Icon
	},
	props: {
		id: {
			type: [Number, String],
			required: true
		},
		page: {
			type: String,
			required: true
		},
		items: {
			type: Array,
			required: false,
			default: () => {
				return [
					{
						id: 1,
						name: 'labels.edit',
						icon: 'edit',
						color: 'has-text-info',
						type: 'edit'
					},
					{
						id: 5,
						name: 'labels.delete',
						icon: 'trash',
						color: 'has-text-danger',
						type: 'delete'
					}
				]
			}
		}
	},
	methods: {
		handleClick(el, id) {
			const type = el.type
			if (type === 'edit') {
				eventHub.$emit(`edit-modal-${this.page}`, {
					id
				})
			}
			else if (type === 'delete') {
				eventHub.$emit(`delete-${this.page}`, {
					id
				})
			}
		}
	},
	computed: {
		...mapGetters('user', ['permissionEnabled'])
	}
}
</script>
