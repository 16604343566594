<template>
	<article class="block placeholder full">
		<div class="column is-flex is-9-mobile is-9-tablet is-4-desktop">
			<div class="block__logo">
				<b-skeleton width="130px" height="40px"></b-skeleton>
			</div>
			<div class="block__content pt-1">
				<h3 class="block__name">
					<b-skeleton width="60%" height="15"></b-skeleton>
					<b-skeleton width="80%" height="10"></b-skeleton>
					<span class="status-process"></span>
				</h3>
			</div>
		</div>
		<div class="block__content column is-3-mobile is-3-tablet is-2-desktop">
			<b-skeleton width="30%" height="15"></b-skeleton>
			<b-skeleton width="60%" height="10"></b-skeleton>
		</div>
		<div class="block__content column is-2-desktop">
			<b-skeleton width="60%" height="15"></b-skeleton>
			<b-skeleton width="30%" height="10"></b-skeleton>
		</div>
		<div class="block__content column is-2-desktop">
			<b-skeleton width="50%" height="15"></b-skeleton>
			<b-skeleton width="50%" height="10"></b-skeleton>
		</div>
		<div class="block__content column is-2-desktop is-align-items-center has-text-center">
			<b-skeleton width="20%" height="15"></b-skeleton>
			<b-skeleton width="60%" height="10"></b-skeleton>
		</div>
	</article>
</template>

<script>
export default {
	name: 'Placeholder'
}
</script>
