<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form autocomplete="off" ref="form" @submit.prevent="handleSubmit(savePerson)">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>
						{{ $t(modalTitle(name)) }}
						<strong>{{ $tc('menu.people') }}</strong>
					</span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="modal-card mb-3">
					<div class="columns is-multiline">
						<div class="column is-12">
							<InputWithValidation name="name" rules="required" :label="$t('fields.full_name')" v-model="person.name" />
						</div>
						<div class="column is-12">
							<InputWithValidation name="date" rules="required|min:10" field="date" :label="$t('fields.birthdate')" v-model="person.birthdate" />
						</div>
						<div class="column is-12">
							<InputWithValidation name="responsible" rules="required|min:14" v-mask="'###.###.###-##'" :label="$t('fields.document')" v-model="person.document" />
						</div>
						<div class="column is-12">
							<SelectWithValidation :label="$tc('menu.insurance')" rules="required" v-model="person.client_id">
								<option v-for="c in clients" :value="c.id" :key="`clients-${c.id}`">{{ c.name }}</option>
							</SelectWithValidation>
						</div>
					</div>
					<small class="modal-updated" v-if="person.updated_at">{{ $t('labels.last_change') }} {{ format(person.updated_at) }}</small>
				</div>
			</div>
			<Footer :loading="loading" />
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import SelectWithValidation from '@/components/inputs/SelectWithValidation'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import Footer from '@/components/modals/Footer'
import '@/mixins/generic'

export default {
	components: {
		Footer,
		SelectWithValidation,
		InputWithValidation,
		ValidationObserver
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			person: {},
			isOpening: false,
			loading: false,
			role: 1,
			permission: [],
			visible: false,
      clients: []
		}
	},
	methods: {
    async getClients() {
      try {
        const { status, data } = await Api.get(`app/clientSelect`)
        if (status === 200) {
          this.clients = data
        }
      } catch (e) {
        console.log(e)
      }
    },
		async update() {
			try {
				this.loading = true
				const response = await Api.put(`people/update/${this.id}`, this.person)
				const { status } = response
				if (status === 200) {
					this.$emit('close')
					history.pushState({}, '', '/people')
					successToast(this.$t('alerts.update.success', [this.$tc('menu.people')]))
					eventHub.$emit('reload-people')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					// const { message } = e.data
					errorToast(this.$t('alerts.update.error', [this.$tc('menu.people')]))
				}
			} finally {
				this.loading = false
			}
		},
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`people/findById/${this.id}`)
					const { status } = response
					if (status === 200) {
						const { data } = response
            this.person = data
						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}
			}
		},
		async store() {
			try {
				this.loading = true
				const response = await Api.post('people/store', this.person)
				const { status } = response
				if (status === 201 || status === 200) {
					this.$emit('close')
					history.pushState({}, '', '/people')
					successToast(this.$t('alerts.create.success', [this.$tc('menu.people')]))
					eventHub.$emit('reload-people')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					errorToast(message)
				}
			} finally {
				this.loading = false
			}

			this.loading = true
			setTimeout(() => {
				this.loading = false
			}, 1000)
		},
		async savePerson() {
			this.name === 'New' ? await this.store() : await this.update()
		}
	},
	mounted() {
		this.findById()
		this.getClients()
	}
}
</script>
